@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html,
#root {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  position: relative;
  min-height: 100vh;
}

*{
  font-family: 'Roboto', sans-serif;
}